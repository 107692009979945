import React from 'react'
import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'

const H = styled.p`
  ${tw`text-black`}

  ${props =>
    props.level === 1 &&
    css`
      ${tw`text-3xl`}
    `}

  ${props =>
    props.level === 2 &&
    css`
      ${tw`text-xl`}
    `}
`

function Heading({ children, ...props }) {
  const level = ~~props.level || 1
  const HeadingTag = 'h' + level
  return (
    <H as={HeadingTag} level={level}>
      {children}
    </H>
  )
}

export default Heading
