import styled from '@emotion/styled'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import {
  animated,
  useSpring,
  useTrail,
  useTransition
} from 'react-spring/web.cjs'
import useHover from 'react-use-hover'
import tw from 'tailwind.macro'
import { ReactComponent as AlertSvg } from './icons/alert.svg'
import ContentRenderer from './sanity-content-renderer'
import Tooltip from './tooltip'

const ItemsContainer = styled.div`
  ${tw`mt-8 flex -mx-2 overflow-x-auto p-1 scrolling-touch`};

  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0)),
    linear-gradient(-90deg, white 0%, rgba(255, 255, 255, 0)) 100% 0,
    radial-gradient(
      farthest-side at 0% 50%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 100% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      100% 0%;

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
`

const Item = styled.div`
  ${tw`cursor-pointer flex flex-col items-center p-4 shadow-md bg-grey-lightest rounded mx-2 my-1`};
`

const ItemImageContainer = styled.div`
  overflow: hidden;
  ${tw`rounded-full relative`};
`

const ItemImageHoverBox = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  left: 0%;
  ${tw`absolute w-full h-full z-10 pin-t`};
`

const AItemImageHoverBox = animated(ItemImageHoverBox)

const ItemImage = styled(Img)`
  width: 150px;
  height: 150px;
`

const ItemText = styled.p`
  ${tw`text-center m-0 mt-3 text-primary whitespace-no-wrap`};
`

const InfoDetails = styled.div`
  ${tw`flex flex-wrap mt-16 pin-l pin-t w-full`};
`

const AInfoDetails = animated(InfoDetails)

const InfoDetailsImages = styled.div`
  margin: -0.75rem;
  ${tw`w-full lg:w-3/5 mt-4 lg:mt-0 lg:pl-10 flex flex-wrap`};
`

const InfoDetailsImagesWrapper = styled.div`
  ${tw`w-1/2`};
`

const InfoDetailsImagesImg = styled(Img)`
  ${tw`rounded shadow m-3`};
  max-width: ${props => props.dimensions.width}px;
`

const InfoDetailsContent = styled.div`
  ${tw`w-full lg:w-2/5`};
`

const Relative = styled.div`
  ${tw`relative`}
`

const HeadingWithBorder = styled.h2`
  ${tw`m-0 pt-1 text-black inline-block text-xl border-0 border-t-2 border-primary border-solid`};
`

const NeedForActionContainer = styled.div`
  ${tw`flex mt-1 items-center text-sm`};

  p {
    ${tw`my-0 ml-1 font-bold`}
  }

  ${props =>
    props.action === 'negligible' &&
    `
    fill: #ffd43b;
    `}

  ${props =>
    props.action === 'contemporary' &&
    `
      fill: #e8590c;
      `}
  
  ${props =>
    props.action === 'urgent' &&
    `
      fill: #f03e3e;
      `}
`

const NeedForActionPill = styled.div`
  ${tw`flex items-center py-1 px-3 rounded-full bg-grey-lighter`};
`

const NeedForAction = ({ action }) => {
  return (
    <NeedForActionContainer action={action}>
      <Tooltip content="Handlungsbedarf">
        <NeedForActionPill>
          <AlertSvg width={24} height={24} />
          <p>
            {action === 'negligible'
              ? 'Vernachlässigbar'
              : action === 'contemporary'
              ? 'Zeitnah'
              : 'Akut'}
          </p>
        </NeedForActionPill>
      </Tooltip>
    </NeedForActionContainer>
  )
}

function InfoPage({ info }) {
  const defaultInsectIdx = 0
  const [activeIdx, setActiveIdx] = useState(defaultInsectIdx)

  const items = info.pestsinsects
  const activeInsect = items[activeIdx || defaultInsectIdx]

  const itemTrail = useTrail(items.length, {
    config: { mass: 1, tension: 250, friction: 26 },
    opacity: 1,
    scale: 1,
    from: { opacity: 0, scale: 0 }
  })

  const contentTransition = useTransition(activeInsect, item => item._key, {
    native: true,
    from: {
      transform: 'translate3d(0,-40px,0)',
      opacity: 0
    },
    enter: {
      transform: 'translate3d(0,0px,0)',
      opacity: 1
    },
    leave: {
      position: 'absolute',
      transform: 'translate3d(0,-40px,0)',
      opacity: 0
    }
  })

  return (
    <div>
      <ItemsContainer>
        {itemTrail.map(({ scale, ...trailStyles }, idx) => {
          const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 })
          const shouldApplyGrayscale = activeIdx === idx || isHovering

          const hover = useSpring({
            ...(shouldApplyGrayscale
              ? { grayscale: 0, translate: '105%' }
              : { grayscale: 0.8, translate: '0%' })
          })

          return (
            <animated.div
              key={idx}
              style={{
                ...trailStyles,
                filter: hover.grayscale.interpolate(s => `grayscale(${s})`),
                transform: scale.interpolate(s => `scale(${s})`)
              }}
              {...hoverProps}
            >
              <Item
                onClick={() => {
                  setActiveIdx(idx)
                }}
              >
                <ItemImageContainer>
                  <AItemImageHoverBox
                    style={{
                      visibility: hover.visibility,
                      transform: hover.translate.interpolate(
                        v => `translateX(${v})`
                      )
                    }}
                  />
                  <ItemImage fluid={items[idx].images[0].image.asset.fluid} />
                </ItemImageContainer>
                <ItemText>{items[idx].title}</ItemText>
              </Item>
            </animated.div>
          )
        })}
      </ItemsContainer>

      <Relative>
        {contentTransition.map(({ item, props, key }) => (
          <AInfoDetails style={props} key={key}>
            <InfoDetailsContent>
              <HeadingWithBorder level={2}>{item.title}</HeadingWithBorder>
              {item.needForAction && (
                <NeedForAction action={item.needForAction} />
              )}
              <ContentRenderer
                blocks={
                  info._rawPestsinsects.find(x => x._key === item._key).content
                }
              />
            </InfoDetailsContent>

            <InfoDetailsImages>
              {item.images.map(image => {
                return (
                  <InfoDetailsImagesWrapper key={image._key}>
                    <InfoDetailsImagesImg
                      dimensions={image.image.asset.metadata.dimensions}
                      fluid={image.image.asset.fluid}
                    />
                  </InfoDetailsImagesWrapper>
                )
              })}
            </InfoDetailsImages>
          </AInfoDetails>
        ))}
      </Relative>
    </div>
  )
}

export default InfoPage
